<script setup lang="ts">
import { useForm } from 'vee-validate'
import { z } from 'zod'

import { Base400Gray } from '~/components/typography'
import { BtnOrange } from '~/components/ui/Buttons'
import VTextInput from '~/components/ui/Inputs/VText.vue'

import { useFieldValidation } from '~/composables/useFieldValidation'

import { useLoginStore } from '~/stores/login'
import { useSnackbarStore } from '~/stores/snackbar'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

import { provideAuthRequestPasswordReset } from '~/api-auth/ResetPassword'

type Emits = {
  close: []
}

const emit = defineEmits<Emits>()

const { t } = useI18n()
const route = useRoute()

const { validate } = useForm()
const fieldName = 'emailForPasswordRecovery'
const {
  value: fieldValue,
  errorMessage: fieldErrorMessage,
  handleBlur: fieldValueValidateOnBlur,
} = useFieldValidation<string>(fieldName, z.string().email(), {
  syncVModel: false,
  validateOnValueUpdate: false,
  initialValue: '',
})
const loading = ref(false)

const { SET_DISPLAY: LOGIN_SET_DISPLAY } = useLoginStore()
const { CREATE: SNACKBAR_CREATE } = useSnackbarStore()

async function handleBtnOrangeClick() {
  if (loading.value) return

  try {
    const { valid } = await validate()
    if (!valid) return

    loading.value = true

    const requestPasswordReset = provideAuthRequestPasswordReset($authFetch)

    await requestPasswordReset(fieldValue.value)
    emit('close')
    LOGIN_SET_DISPLAY(false)
    SNACKBAR_CREATE({
      props: {
        text: t('auth.passwordReset.success'),
      },
    })
    trackEvent(
      {
        category: 'Login',
        action: 'Reset password',
      },
      route,
    )
  } catch (_) {
    loading.value = false
  }
}
</script>

<template>
  <div class="tw-relative tw-grid tw-w-full tw-grid-cols-1 tw-gap-6">
    <Base400Gray>{{ t('auth.passwordReset.text') }}</Base400Gray>
    <VTextInput
      v-model="fieldValue"
      :p-validity="!fieldErrorMessage"
      :label="t('auth.login.label.email')"
      :placeholder="t('auth.login.placeholder.email')"
      left-picto="fas fa-envelope"
      @blur="fieldValueValidateOnBlur($event, true)"
    >
      <template #err-message>{{ fieldErrorMessage }}</template>
    </VTextInput>
    <BtnOrange
      :loading="loading"
      class="tw-mb-6"
      @click="handleBtnOrangeClick"
      >{{ t('common.confirm') }}</BtnOrange
    >
  </div>
</template>
